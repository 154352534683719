/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  IconButton,
  Dialog,
  MenuList,
  MenuItem,
  Popper,
  Grow,
  ClickAwayListener,
  Paper,
  Icon,
  Menu,
} from '@mui/material';
import styles from './topmenu.module.scss';
import { IconMenu, mQSBanner1 } from '../../../../assets/images';
import { RootState } from '../../../../store/create-store';
import AccountControl from '../../../../common/components/account-control/account-control.component';
import ENVIRONMENTS from '../../../../environments';
import { useHistory, useLocation } from 'react-router';
import Notifications from '../../../../common/components/notifications/notifications.component';
import {
  logout,
  setRedirectURI,
} from '../../../../store/common/common.reducer';
import {
  AppstoreWhiteBadge,
  PlaystoreBadge,
  QuickCamLogoHeader,
  QuickCamLogoHeaderNew,
} from '../../../../assets/svg';
import useWindowDimensions from '../../../../common/hooks/useWindowDimensions';
import { useAppData } from '../../../../common/contexts/app-data.context';

import { getProfileImageUrl } from '../../../../common/helpers/helpers';
import ProfileBadge from '../../../../common/components/profile-badge/profile-badge.component';
import { AuthService } from '../../../../common/services/auth/auth.service';

const TopMenuBar = (props: { referredBy: any }) => {
  const { referredBy } = props;
  const commonState = useSelector((state: RootState) => state.common);
  const { serviceAvailable } = useAppData();

  const { auth, user } = commonState;
  const isLoggedIn = Boolean(auth) && Boolean(user);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { search } = location;
  const params = useMemo(() => new URLSearchParams(search), []);
  const { width } = useWindowDimensions();

  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const [moreAnchor, setMoreAnchor] = React.useState<null | HTMLElement>(null);
  const showMore = Boolean(moreAnchor);

  const handleOpenMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreAnchor(event.currentTarget);
  };

  const handleCloseMore = (event: any, componentId?: string) => {
    setMoreAnchor(null);
    scrollToContent(event, componentId);
  };

  const [openMenu, setOpenMenu] = useState(false);
  const [fixedHeader, setFixedHeader] = useState(false);
  const [showSignupButton, setShowSignupButton] = useState(
    !isLoggedIn && serviceAvailable,
  );

  useEffect(() => {
    if (referredBy) {
      setShowSignupButton(!isLoggedIn);
    } else {
      setShowSignupButton(!isLoggedIn);
    }
  }, [isLoggedIn]);

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 64) {
        setFixedHeader(true);
      } else {
        setFixedHeader(false);
      }
    });
  }, []);

  const scrollToContent = (event: any, componentId?: string) => {
    if (!componentId) {
      window.scrollTo(0, 0);
    } else {
      var element = document.getElementById(componentId) as any;
      var headerOffset = 60;
      var elementPosition = element.offsetTop;
      var offsetPosition = elementPosition - headerOffset;
      document.documentElement.scrollTop = offsetPosition;
      document.body.scrollTop = offsetPosition;
    }
    closeMenu(event);
  };

  const closeMenu = (event: any) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenMenu(false);
    document.body.classList.remove('overflow-hidden');
  };

  const HeaderMenus = [
    {
      id: 'home',
      label: 'Home',
    },
    {
      id: 'features',
      label: 'Features',
    },
    // {
    //   id: 'raiseMoney',
    //   label: 'Raise Money',
    // },
    // {
    //   id: 'preLaunch',
    //   label: 'Pre-Launch',
    // },
    {
      id: 'testimonials',
      label: 'Testimonials',
    },
    // {
    //   id: 'incomeOpportunities',
    //   label: 'Income Opportunity',
    // },
    {
      id: 'subscriptionPlan',
      label: 'Subscription Plan',
    },
    // {
    //   id: 'giftcards',
    //   label: 'Gift Cards',
    // },
    {
      id: 'faqs',
      label: 'FAQs',
    },
    // {
    //   id: 'summary',
    //   label: 'Summary',
    // },
  ];

  const redirectToSignUp = () => {
    const redirectUri =
      window.location.origin +
      (location.pathname === '/' ? '' : location.pathname);
    if (location.pathname !== '/')
      window.localStorage.setItem('redirect_uri', redirectUri);
    dispatch(
      setRedirectURI({
        url: window.location.href || '',
        refCode: params.get('refCode') || '',
      }),
    );
    const signUphURL = `${ENVIRONMENTS.KEYCLOAK_ACCOUNT}/protocol/openid-connect/registrations?client_id=${ENVIRONMENTS.KEYCLOAK_CLIENT_ID}&response_mode=fragment&response_type=code&login=true&redirect_uri=${redirectUri}&from=Signup`;
    window.location.href = signUphURL;
  };

  const redirectToLogin = () => {
    const redirectUri =
      window.location.origin +
      (location.pathname === '/' ? '' : location.pathname);
    if (location.pathname !== '/')
      window.localStorage.setItem('redirect_uri', redirectUri);
    dispatch(
      setRedirectURI({
        url: window.location.href || '',
        refCode: params.get('refCode') || '',
      }),
    );
    const loginUrl = `${ENVIRONMENTS.KEYCLOAK_ACCOUNT}/protocol/openid-connect/auth?client_id=${ENVIRONMENTS.KEYCLOAK_CLIENT_ID}&response_mode=fragment&response_type=code&login=true&redirect_uri=${redirectUri}&from=Login`;
    window.location.href = loginUrl;
  };

  const goTo = (url: string, event: any) => {
    history.push(url);
    closeMenu(event);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenMenu = () => {
    setOpenMenu(true);
    document.body.classList.add('overflow-hidden');
  };

  const logoutHandle = async () => {
    try {
      await new AuthService().logout();
    } finally {
      dispatch(logout());
    }
  };

  const shouldRenderUserProfile =
    isLoggedIn && ((width < 768 && !referredBy) || width >= 768);

  return (
    <section className={styles.container}>
      <div
        className={[
          styles.header,
          fixedHeader ? styles.fixed : '',
          !isLoggedIn ? styles.centerLogo : '',
        ].join(' ')}
      >
        <IconButton
          className={[styles.iconContainer, styles.mobile].join(' ')}
          onClick={handleOpenMenu}
          size="large"
        >
          <img src={IconMenu} alt="IconMenu" />
        </IconButton>
        <div
          className={width > 768 ? styles.brandTM : styles.mbrandTM}
          onClick={scrollToContent}
        >
          <img src={QuickCamLogoHeaderNew} />
        </div>

        {referredBy && width < 768 && (
          <div className={styles.referralSection} onClick={() => setOpen(true)}>
            <div>
              <span>Your Inviter</span>
              <p>{referredBy?.publicDisplayName}</p>
            </div>
            <img
              src={getProfileImageUrl(
                referredBy?.profileImageURL || QuickCamLogoHeader,
              )}
              alt="img"
              className={styles.profilePic}
            />
          </div>
        )}
        {/* <Dialog
          fullScreen
          open={openMenu}
          onClose={closeMenu}
          // className={styles.menuDialog}
          classes={{
            paper: styles.menuDialog,
          }}
        >
          <Toolbar className={styles.toolbar}>
            <IconButton onClick={() => setOpenMenu(false)}>
              <Icon className={styles.closeIcon}>close</Icon>
            </IconButton>
          </Toolbar>
          <div className={styles.mobileMenuWrapper}>
            <h2>Menu</h2>
            <MenuList
              autoFocusItem={openMenu}
              id="menu-list-grow"
              className={styles.menu}
            >
              {isLoggedIn && (
                <MenuItem
                  onClick={() => history.push('/u/')}
                  className={styles.menuItem}
                >
                  Dashboard
                </MenuItem>
              )}
              {HeaderMenus.map(menu => (
                <Button
                  variant="text"
                  className={styles.actionButton}
                  onClick={() => history.push('#features')}
                  key={menu.id}
                >
                  {menu.label}
                </Button>
              ))}
              {!isLoggedIn && (
                <MenuItem onClick={redirectToLogin} className={styles.menuItem}>
                  Login
                </MenuItem>
              )}
              {!isLoggedIn && (
                <MenuItem
                  onClick={redirectToSignUp}
                  className={styles.menuItem}
                >
                  Sign Up
                </MenuItem>
              )}
            </MenuList>
            <DialogActions className={styles.actionMenu}>
              <h5>Available on:</h5>
              <div>
                <img src={GooglePlay} alt="icon" />
                <img src={AppleStore} alt="icon" />
              </div>
            </DialogActions>
          </div>
        </Dialog> */}

        <Popper
          open={openMenu}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          className={styles.menuPopper}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper className={styles.paper}>
                <div className={styles.backgroundBlur} />
                <ClickAwayListener onClickAway={closeMenu}>
                  <>
                    <div className={styles.fixedHeader}>
                      <p className={styles.menuHeader}>Menu</p>
                      <Icon onClick={closeMenu} className={styles.iconClose}>
                        close
                      </Icon>
                    </div>
                    <MenuList
                      autoFocusItem={openMenu}
                      id="menu-list-grow"
                      className={styles.menu}
                    >
                      {isLoggedIn && (
                        <MenuItem
                          onClick={event => goTo('/u/dashboard', event)}
                          className={styles.menuItem}
                        >
                          Dashboard
                        </MenuItem>
                      )}
                      {HeaderMenus.map(menu => (
                        <MenuItem
                          onClick={(event: any) =>
                            scrollToContent(event, menu.id)
                          }
                          className={styles.menuItem}
                        >
                          {menu.label}
                        </MenuItem>
                      ))}
                      {showSignupButton && !isLoggedIn && (
                        <MenuItem
                          onClick={redirectToLogin}
                          className={styles.menuItem}
                        >
                          Login
                        </MenuItem>
                      )}
                      {showSignupButton && (
                        <MenuItem
                          onClick={redirectToSignUp}
                          className={styles.menuItem}
                        >
                          Sign Up
                        </MenuItem>
                      )}
                      {isLoggedIn && (
                        <MenuItem
                          onClick={logoutHandle}
                          className={styles.menuItem}
                        >
                          Logout
                        </MenuItem>
                      )}
                    </MenuList>
                    <div className={styles.platforms}>
                      <p>AVAILABLE NOW</p>
                      <div className={styles.divider} />
                      <div className={styles.apps}>
                        <div className={styles.googleBadges}>
                          <img src={PlaystoreBadge} alt="playstore" />
                          <span>Google Play</span>
                        </div>
                        <div className={styles.appleBadges}>
                          <img src={AppstoreWhiteBadge} alt="appstore" />
                          <span>Apple Store</span>
                        </div>
                      </div>
                    </div>
                  </>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <div className={styles.navigation}>
          {HeaderMenus.slice(0, 6).map(menu => (
            <Button
              variant="text"
              className={styles.actionButton}
              onClick={(event: any) => scrollToContent(event, menu.id)}
              key={menu.id}
            >
              {menu.label}
            </Button>
          ))}
        </div>
        {/* <Button
          id="basic-button"
          aria-controls={showMore ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={showMore ? 'true' : undefined}
          onClick={handleOpenMore}
          className={styles.showMoreBtn}
        >
          More
          <Icon fontSize="small">expand_more</Icon>
        </Button> */}
        <Menu
          id="basic-menu"
          anchorEl={moreAnchor}
          open={showMore}
          onClose={() => {
            setMoreAnchor(null);
          }}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {HeaderMenus.slice(6, HeaderMenus.length).map(menu => (
            <MenuItem onClick={event => handleCloseMore(event, menu.id)}>
              {menu.label}
            </MenuItem>
          ))}
        </Menu>
        {isLoggedIn && (
          <Button
            variant="outlined"
            className={styles.actionButtonAuth}
            onClick={() => history.push('/u/')}
            style={{
              marginRight: '16px',
            }}
          >
            Dashboard
          </Button>
        )}
        {shouldRenderUserProfile ? (
          <div className={styles.userProfile}>
            <div className={styles.notificationsIcon}>
              <Notifications iconColor="#007aff" />
            </div>
            <AccountControl user={user} />
          </div>
        ) : (
          <div></div>
        )}
        {showSignupButton && (
          <Button
            variant="outlined"
            className={styles.actionButtonAuth}
            onClick={redirectToSignUp}
          >
            Sign Up
          </Button>
        )}
        {showSignupButton && !isLoggedIn && (
          <Button
            variant="outlined"
            className={styles.actionButtonAuth}
            onClick={redirectToLogin}
          >
            Log In
          </Button>
        )}
      </div>
      {width < 768 && (
        <div
          className={`${styles.qsStickyBanner} ${
            fixedHeader
              ? styles.scrollQsStickyBanner
              : styles.scrollQsStickyBannerDown
          }`}
        >
          <img className={styles.mQS_banner} src={mQSBanner1} alt="img" />
        </div>
      )}
      <Dialog
        open={open}
        disableEscapeKeyDown
        onBackdropClick={handleClose}
        onClick={handleClose}
        PaperProps={{
          className: styles.referralModal,
        }}
      >
        <div className={styles.modalContainer}>
          <div className={styles.modalHeader}>
            <p>Your Inviter</p>
            <p>X</p>
          </div>
          <ProfileBadge
            user={referredBy}
            showBadges
            from="quickstarter"
            badgeSize="xSmall"
            badgeClass={styles.referralBadgeWrapper}
            additionalStyle={styles.refProfilePic}
            badgeWrapperClass={styles.badgeMainWrapper}
          />
        </div>
      </Dialog>
    </section>
  );
};

export default TopMenuBar;
